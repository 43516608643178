<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">LEAVE REQUESTS</span>
        </v-card-title>

        <v-tabs v-model="tabValue" background-color="white" grow dark center-active centered :show-arrows="true"
          active-class="activ" class="nonactiv" style="font-family: kumbhSemibold">
          <v-tabs-slider color="#005f32"></v-tabs-slider>
          <v-tab href="#tab-1"> <span style="color: black">Leave Requests</span></v-tab>
          <v-tab href="#tab-2"><span style="color: black">Withdrawn Leave Requests</span></v-tab>
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-layout wrap justify-start py-5>
                <v-flex xs12 pl-3 text-left>
                  <CommonLeveReq @stepper="winStepper" />
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat>
              <v-layout wrap justify-start py-5>
                <v-flex xs12 pl-3 text-left>
                  <WithdrawLeaveReq @stepper="winStepper" />
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
            v-model="currentPage" color="#005f32"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>

import CommonLeveReq from "./commonLeveReq.vue";
import WithdrawLeaveReq from "./withdrawLeaveReq.vue";
export default {
  components: {
    CommonLeveReq,
    WithdrawLeaveReq,

  },
  data: () => ({
    showsnackbar: false,
    tabValue: "tab-1",
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    status: "",

  }),
  // computed: {
  //   computedDateFormatted () {
  //     return this.formatDateMonth(this.date)
  //   },
  // },

  methods: {
    winStepper(item) {
      window.scrollTo(0, 0);
      if (item.ref == "page1Tab") {
        if (
          item.level
        ) {
          this.tabValue = item.level;
        }
      }
    },

  },
};
</script>
<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>