<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#005f32"
        spinner="bar-fade-scale"
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex>
          <!-- <v-card> -->
            <v-card-title class="elevation-0">
              <v-flex xs12 align-self-center pt-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span style="font-family: kumbhRegular; font-size: 14px"
                        >*Select the options to filter the results</span
                      >
                    </v-flex>
                    <v-flex
                      xl3
                      lg3
                      md3
                      pb-2
                      sm6
                      xs12
                      pl-0
                      pl-xs-0
                      pl-sm-1
                      pl-md-1
                      pl-lg-1
                      pl-xl-1
                      pr-0
                      pr-xs-0
                      pr-sm-2
                      pr-md-2
                      pr-lg-2
                      pr-xl-2
                    >
                      <v-select
                        :items="lists"
                        dense
                        v-model="status"
                        item-text="name"
                        item-value="value"
                        outlined
                        class="rounded-xl"
                      ></v-select>
                    </v-flex>
      
                    <v-flex
                      xl3
                      lg3
                      md3
                      pb-2
                      sm6
                      xs12
                      px-0
                      px-xs-0
                      px-sm-2
                      px-md-2
                      px-lg-2
                      px-xl-2
                    >
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            outlined
                            hide-details
                            dense
                            class="rounded-xl"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          no-title
                          type="month"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-card-title>
      
          <v-layout wrap justify-start px-5 v-if="leaveArray.length > 0">
            <v-flex xs12>
              <v-simple-table class="align-center">
                <template v-slot:default>
                  <thead style="background-color: #005f32">
                    <tr>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Employee ID
                      </th>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Employee Name
                      </th>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Leave Category
                      </th>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Applied Date
                      </th>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Day(s)
                      </th>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Status
                      </th>
                      <th
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        "
                        class="text-left"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="align-center">
                    <tr
                      class="text-left align-center"
                      v-for="(item, i) in leaveArray"
                      :key="i"
                    >
                      <td>
                        <span
                          v-if="item.uid"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.uid.employeeId }}</span
                        >
                      </td>
                      <td>
                        <span
                          v-if="item.uid"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.uid.name }}</span
                        >
                      </td>
                      <td>
                        <span
                          v-if="item.leaveCategory"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.leaveCategory }}</span
                        >
                      </td>
  
                      <td>
                        <span
                          v-if="item.create_date"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ formatDate(item.create_date) }}</span
                        >
                      </td>
                      <td>
                        <span
                          v-if="item.leaveRequiredOn"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.leaveRequiredOn.length }}</span
                        >
                      </td>
                      <td>
                        <span
                          v-if="item.status"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.status }}</span
                        >
                      </td>
                      <td>
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-left pa-2>
                            <router-link
                              :to="
                                '/Attendance/leaveRequestsDetails?id=' + item._id
                              "
                            >
                              <v-icon title="View" color="#005f32"
                                >mdi-eye</v-icon
                              >
                            </router-link>
                          </v-flex>
                        </v-layout>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
          <v-layout wrap v-else>
            <v-flex xs12 align-self-center pa-16>
              <span class="kumbhRegular">No Data found!</span>
            </v-flex>
          </v-layout>
          <!-- </v-card> -->
        </v-flex>
      </v-layout>
  
      <v-layout wrap justify-center v-if="pages > 1">
        <v-flex align-self-center>
          <div class="pt-2">
            <v-pagination
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#005f32"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data: (vm) => ({
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      status: "",
      lists: [
        {
          name: "All",
          value: "",
        },
        { name: "Pending", value: "pending" },
        { name: "Approved", value: "approved" },
        { name: "Rejected", value: "rejected" },
        { name: "Withdrawn", value: "withdrawn" },
      ],
      leaveArray: [],
      data: [],
      name: null,
      assetId: null,
      date: new Date().toISOString().substr(0, 7),
      dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
      computedDateFormatted: "",
      menu1: false,
      menu2: false,
    }),
    // computed: {
    //   computedDateFormatted () {
    //     return this.formatDateMonth(this.date)
    //   },
    // },
    beforeMount() {
      this.computedDateFormatted = this.formatDateMonth(this.date);
      if (!this.$store.state.date) {
        this.getData();
        return this.computedDateFormatted;
      } else if (
        this.$store.state.status ||
        this.$store.state.date ||
        this.currentPage
      ) {
        console.log("haii", this.computedDateFormatted);
        this.date = this.$store.state.date;
        this.status = this.$store.state.status;
        this.getData();
      }
    },
    watch: {
      date() {
        this.currentPage = 1;
        this.computedDateFormatted = this.formatDateMonth(this.date);
        if (this.computedDateFormatted) {
          this.getData();
          this.$store.commit("changeDate", this.date);
        }
      },
      status() {
        this.currentPage = 1;
        this.getData();
        this.$store.commit("changeStatus", this.status);
      },
      currentPage() {
        this.getData();
      },
    },
  
    methods: {
      getData() {
        this.appLoading = true;
        //   if (this.date !== "") {
        //     this.date= this.formatDateMonth(this.date);
        //   }
        //   if (this.formatDateMonth(this.date) === "1-1970") {
        //     this.date = null;
        //   }
        axios({
          url: "/employee/leave/application/list",
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            page: this.currentPage,
            limit: 20,
            month: this.computedDateFormatted,
            status: this.status,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.leaveArray = response.data.data;
            this.totalData = response.data.totalLength;
            this.pages = Math.ceil(this.totalData / response.data.limit);
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      formatDate(item) {
        var dt = new Date(item);
        var year = dt.getFullYear();
        dt = dt.toString();
        var strTime = dt.slice(4, 10) + " " + year;
        return strTime;
      },
      formatDateMonth(date) {
        if (!date) return null;
        const [year, month] = date.split("-");
        return `${month}-${year}`;
      },
    },
  };
  </script>
  <style>
  .tableHead {
    font-size: 15px;
    color: #ffffff;
    font-family: kumbhMedium;
  }
  </style>
    